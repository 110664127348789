<template>
  <BaseLayout v-slot>
    <section class="static pt-7">
      <b-container>
        <b-row>
          <b-col lg="3" class="d-none d-lg-block">
            <SidebarLinks :sidebarData="sidebarData" />
          </b-col>
          <b-col lg="9">
            <h1>{{ $t("payment_success") }}</h1>
            <div v-if="transactionId != null">
              {{ $t("transaction") }}: {{ "#" + transactionId }}
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_lommel_dt1_europe/core/components//BaseLayout";
import SidebarLinks from "@/esf_lommel_dt1_europe/core/components//SidebarLinks";
import { getOrderInfoFromHash } from "@storefront/core/data-resolver/cart";
import config from "@storefront/core/lib/utils/config";
import { checkEmailExist } from "@storefront/core/data-resolver/user";

export default {
  name: "PaymentSuccess",
  components: {
    BaseLayout,
    SidebarLinks,
  },
  computed: {
    transactionId() {
      if (typeof this.$store.state.route.query.transactionid != "undefined") {
        return this.$store.state.route.query.transactionid;
      }
      return null;
    },
    user: {
      get() {
        return this.$store.getters["user/getCurrentUser"];
      },
      set() {},
    },
  },
  async mounted() {
    const bcrumb = { current: "success", routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);

    if (this.transactionId != null) {
      const hashId = localStorage.getItem("orderHash");
      const order = await getOrderInfoFromHash(hashId);
      this.order_id = parseInt(atob(order.id));
      if (!this.$store.getters["user/getIsLoggedIn"]) {
        this.user.email = order.customer_email;
        if (!(await checkEmailExist(this.user.email))) {
          this.emailExists = true;
        } else {
          this.emailExists = false;
        }
      }
      if (order != false) {
        this.user.email = order.customer_email;
        if (this.$gtm.enabled() == true) {
          const items = [];
          order.items.forEach((element) => {
            let item = {};
            item.id = element.sku;
            item.name = element.name;
            item.price = element.product_sale_price;
            item.quantity = element.quantity_ordered;
            items.push(item);
          });
          if (this.$gtm.enabled()) {
            window.dataLayer.push({
              event: "purchase",
              ecommerce: {
                currencyCode: "EUR",
                purchase: {
                  actionField: {
                    id: this.transactionId,
                    affiliation: config.app_name,
                    revenue: order.total.subtotal.value,
                    tax: order.total.total_tax.value,
                    shipping: order.total.total_shipping.value,
                  },
                  products: items,
                },
              },
            });
          }
        }
      }
    }
  },

  data: () => ({
    sidebarData: {
      heading: "Categorie",
    },
  }),
};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 16px;
}

ul {
  li {
    padding-left: 15px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 12px;
      left: 1px;
      width: 4px;
      height: 4px;
      background: #000;
      border-radius: 100%;
    }
  }
}

.mb-lg-27 {
  margin-bottom: 27px;
}

.font-weight-medium {
  font-weight: 500;
}
</style>
